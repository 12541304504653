.page-header {
  width: 100%;

  .page_title_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header_button_section_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;

  .input {
    margin-right: 10px;
    padding: 5px 15px;
    min-height: 44px;
    display: flex;
    align-items: center;
    min-width: 118px;
    justify-content: center;
    margin-bottom: 15px;
  }

  select {
    &.input {
      option {
        color: #3e4b5b;
      }
    }
  }

  .search_box {
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .datePicker_wrap {
    margin-right: 10px;
    margin-bottom: 15px;
  }
}

.search_box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  padding-left: 5px;

  i {
    font-size: 24px;
    opacity: 0.6;
  }

  .form-control {
    border: none;
    padding-left: 10px;
  }
}

.datePicker_wrap {
  max-width: 100%;

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #fff;
      min-height: 45px;

      .input {
        width: 100%;
        margin: 0;
        border: 0;
      }

      svg {
        position: static;
        left: 0;
        top: 0;
      }
    }
  }
}

.pagination_wrap {
  padding-top: 30px;
  justify-content: flex-end;
  display: flex;

  .pagination {
    li {
      margin: 0 3px;

      .page-link {
        border-color: #ce7ffd;
        color: #ce7ffd;

        &.active {
          color: #fff;
          background-color: #ce7ffd;
        }
      }
    }
  }
}

.action_button_wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .switch_button {
    display: flex;
    align-items: center;

    .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }
}

.action_button {
  padding: 5px 6px;
}

.wrap_selectCstm {
  select {
    width: 100%;
    margin-bottom: 15px;

    option {
      color: #3e4b5b;
    }
  }

  .cstm_padding_set {
    padding: 0px 10px;

    .pageSelect {
      padding: 0px 5px;
    }

    .wrap_select {
      // background-image: url(../public/assets/images/downdrop.svg);
      // background-repeat: no-repeat;
      // background-position: right 0.75rem center;
      // background-size: 16px 12px;
      // -webkit-appearance: none;
    }
  }

  .card {
    .card-body {
      .cstm_row_column {
        .form-group {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;

          label {
            margin-bottom: 0 !important;
            padding: 0px 0px;
          }

          .form-control {
            padding: 0.74rem 0.475rem;
          }
        }
      }
    }
  }
}

.card .card-body {
  padding: 1rem 1rem;
}

.pageSelect {
  height: 39px;
  margin-top: 2px;
  border-radius: 3px;
  border: none;
  background-color: white;
  font-size: 13px;
}

.custm_button {
  width: auto;
  height: auto;
  padding: 5px 16px;
  border-radius: 5px;
}

.vl {
  border-left: 3px solid rgb(211, 215, 211);
}

.sidebar {
  .nav {
    &.cstm_nav {
      height: calc(100vh - 70px);
      overflow-y: auto;
    }

    &::-webkit-scrollbar-track-piece {
      background: #888;
    }

    &::-webkit-scrollbar-thumb {
      background: #eee;
    }
  }
}
.cstm_p_count_bundle {
  font-size: 18px;
  margin-bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cstm_p_count_bundle {
  font-size: 18px;
  margin-bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action_button_wrap {
  .switch_button {
    label {
      .MuiSwitch-root {
        width: 33px;
        height: 17px;

        .MuiButtonBase-root {
          .MuiSwitch-thumb {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .mdi-plus-circle {
      font-size: 24px;
      margin-right: 5px;
    }

    .mdi-minus-circle {
      font-size: 24px;
      margin-left: 5px;
    }

    .cstm_p_count {
      font-size: 18px;
      margin-bottom: 0;
      margin: 0;

    }
  }

  .action_button {
    .cstm_eye {
      font-size: 18px;
    }

    .mdi-table-edit {
      font-size: 20px;
      color: #3e4b5b;
    }
  }
}

.cstm_btn_wrap_theme {
  line-height: 40px;
  padding: 0rem 1rem;
  min-height: 40px;
}

.cstm_btn_wrap_theme2 {
  padding: 0rem 0rem !important;
  min-height: 40px !important;
  line-height: 40px !important;
  margin-bottom: 0 !important;
}

.cstm_date_picker {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      min-height: 40px;

      .date_picker {
        font-size: 13px;

        &::placeholder {
          font-size: 13px;
        }
      }
    }
  }
}

.btn-outline-primary {
  transition: all 0.4s ease-in-out;

  &:hover {
    color: #fff;
  }
}

.btn-outline-info {
  transition: all 0.4s ease-in-out;

  &:hover {
    color: #fff;
  }
}

.btn-outline-success {
  transition: all 0.4s ease-in-out;

  &:hover {
    color: #fff;
  }
}

.wrap_Add_retailer .form-group.row {
  // align-items: center;
}

.wrap_Add_retailer .form-group.row .error {
  margin-top: 5px;
  font-size: 12px;
}

.wrap_Add_retailer .form-group.row .col-form-label {
  padding: 8px;
  margin-bottom: 0;
  text-align: left;
}

.wrap_Add_retailer .form-control {
  padding: 10px 10px !important;
}

.cstm_margin_remove {
  margin-bottom: 0 !important;
}

select.form-control {
  background-color: #ffffff;
  border: 1px solid #ebedf2;
  outline: unset;
  color: unset;
  appearance: auto !important;
}

.form-control {
  color: #4e5862 !important;
  font-weight: 400 !important;
}

select.form-control:focus {
  border-color: #86b7fe;
  outline: unset;
}

.wrap_Add_retailer .form-sample .row {
  padding-left: 10px;
}

.cstm_width {
  width: 12.4%;
}

.cstm_width2 {
  width: calc(100% - 12.4%);
}

.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
  animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {

  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }

  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }

  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }

  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.727926px 12.727926px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }

  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.727926px 12.727926px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }

  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 0 #9880ff;
  }

  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 0 #9880ff;
  }

  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 0 #9880ff;
  }
}

.cstmheader_nav {
  li {
    .dropdown {
      button {
        background-color: unset;
        border: unset;
        margin: 0 !important;
        padding: 10px 10px;

        &:focus {
          background-color: unset;
          border: unset;
          outline: unset;
          box-shadow: unset !important;
        }
      }

      .navbar-dropdown {}

      .dropdown-menu {
        .dropdown-item {
          padding: 5px;

          &:hover {
            background-color: #f8f9fa;
            color: #000;
          }
        }
      }

      .cstm_color {
        color: #4e5862;
        font-weight: 400;
      }

      &.show {
        .btn-primary {
          &.dropdown-toggle {
            background-color: unset;
            border-color: unset;
          }
        }
      }
    }
  }
}

.quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link.dropdown-toggle:after {
  color: #9880ff;
}

.modal {
  .modal-dialog {
    .modal-content {
      background-color: #fff;

      .modal-header {
        border: unset;
      }

      .cstmModal_box {
        font-size: 24px;
        text-align: center;
      }

      .modal-footer {
        border: unset;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.cstm_modal_img_wrap {
  width: 300px;
  height: 250px;
  margin: 0 auto;

  img {
    width: 100% !important;
    height: 100%;
    object-fit: fill;
  }
}

.cstm_position_set {
  position: relative;

  .cstm_wrap_position {
    margin-bottom: 64px;

    .cstm_append_group {
      position: absolute;
      left: 140px;
      top: 0;
    }
    
    .upload-button-wrapper {
      position: relative;
    }
    
    .upload-button-wrapper small {
      position: absolute;
      left: 0;
      top: 30px; 
    }

    label {
      position: absolute;
      top: 12px;
      left: 26px;
    }

    .cstm_ere {
      top: 11px;
      position: absolute;
      font-size: 12px;
      left: 300px;
    }
  }
}

.form-control:disabled {
  background-color: #fff !important;
}

.form-control[readonly] {
  background-color: #fff !important;
}

.cstm_img_set_retails {
  width: 250px;
  height: 150px;
  margin-top: 10px;

  img {
    height: 100%;
    width: 100% !important;
    object-fit: fill;
  }

  &.cstm_image_retails_set {
    position: relative;

    i {
      position: absolute;
      font-size: 26px;
      cursor: pointer;
    }
  }
}

.page-title-icon {
  cursor: pointer;
}

.mdi-chevron-double-left {
  cursor: pointer;
}

.btn--loading.loader_wrapper {
  position: fixed;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #aaaaaa73;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 9999;
  top: 0;
}

.btn--loading.loader_wrapper .dot-spin {
  z-index: 99999;
  position: relative;
}

.Cstm_see_description a {
  font-size: 12px;
  color: #9c27b0;
}

// .cstm_btn_wrap_theme4{
//   min-height: 0;
//   line-height: 0;
//   padding: 12px 10px;
// }
.activeTabAccepted {
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.activeTabAccepted:hover {
  color: #fff;
}

.activeTabshipped {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.activeTabshipped:hover {
  color: #fff;
}

.activeTabdelivered {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.cstm_label_form {
  flex-direction: row-reverse;
  margin-left: 0 !important;
}

.cstm_mdi_edit_accordian {
  font-size: 24px;
}

.activeTabdelivered:hover {
  color: #fff;
}

.margin-bottom-unset {
  margin-bottom: 0;
  text-decoration: none;
  color: #000;
}

.class_overwrite_anchor {
  text-decoration: unset;
}

.btn-outline-dark:hover {
  color: #fff;
}

.mdi_arrow_bold {
  color: #000;
  font-size: 24px;
}

.btn-outline-danger:hover {
  color: #fff;
}

.activeTabcancelled {
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #fff;
}

.activeTabcancelled:hover {
  color: #fff;
}

#error_cstm {
  margin-top: 5px;
}

.notification_btn .align-center {
  align-items: center;
}

.form-control .MuiInputBase-root {
  line-height: unset;
}

.form-control .MuiInputBase-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: unset;
  padding: 14px 14px;
}

.cstm_dateAlign .align-center {
  align-items: unset;
}

.category_cstm_ul {
  li {
    display: flex;

    h5 {
      max-width: 150px;
      width: 100%;
      text-align: start;
    }

    p {
      text-align: start;
      margin-bottom: 10px;
    }
  }
}

.cstmselect_drop {
  padding: 0.94rem 0.375rem !important;
}

.error {
  font-size: 13px;
}

.cstm_delete_icon {
  margin-bottom: 5px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ebedf2 !important;
}

.modal {
  .modal-dialog.modal-lg {
    max-width: 700px;
  }
}

.cstm_card_user_data {
  .card-body {
    .row {
      .col-12 {
        p {
          text-align: start;

          b {
            font-size: 18px;
            max-width: 190px;
            width: 100%;
            display: inline-block;
            text-align: start;
          }
        }
      }
    }
  }
}

.action_button_wrap {
  .cstm_toogle_wrap {
    background-color: transparent;
    border: unset;
    font-size: 24px;
  }

  .cstm_expand {
    font-size: 22px;
    margin-right: 7px;
  }
}

.cstm_bundle_style {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;
}

.cstm_drop_styling {
  .dropdown {
    .dropdown-menu {
      .cstm_flex_style {
        display: flex;
        align-items: center;
        padding: 0px 15px;

        label {
          margin-bottom: 0;

        }

        .dropdown-item {
          &:hover {
            color: #000;
          }
        }

        td {
          a {
            padding: 0px 6px;
            margin-right: 0 !important;
            min-height: 25px;
            line-height: 25px;
          }

          .switch_button {
            .mdi-minus-circle {
              margin-right: 5px;
              margin-left: 0;
              font-size: 20px;
            }

            .mdi-plus-circle {
              margin-right: 0;
              margin-left: 5px;
              font-size: 20px;
            }

          }
        }
      }
    }
  }
}
.cstm_bundle_style {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;
}
.cstm_image_vendor{
  .cstm_modal_img_wrap{
    margin: unset;
  }
}

.cstm_height_Set {
  height: 50vh;
  overflow-y: scroll;
}

.cstm_styling_drop_set {
  max-height: 250px;
  overflow-y: scroll;
  max-width: 420px;
  width: 100%;
  padding-bottom: 0;
}

.cstm_card_height {
  // height: 75vh;
}

.cstm_flex_wrap {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #ddd;
  text-align: center;
  margin-bottom: 0;
}

.dropdown-footer button {
  width: 100%;
  padding: 10px;
}

.cart-table {
  border-collapse: collapse;
  width: 100%;
}

.cart-table th,
.cart-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.cart-table th {
  background-color: #f0f0f0;
}

.cart-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cart-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin: 10px;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.cart-buttons button+button {
  margin-left: 10px;
}