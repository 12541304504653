.btn--loading {
  font-size: 0;
}

.btn--loading span {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.btn--loading span b {
  background: white;
  animation-delay: 0.1s;
  margin: 0 5px;
  animation-delay: 0s;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.btn--loading span b:first-child {
  animation-delay: 0s;
  margin: 0;
}

.btn--loading span b:last-child {
  animation-delay: 0.2s;
  margin: 0;
}

.pagination ul li .page-link:hover {
  background-color: #ce7ffd;
  border: unset;
  color: #fff;
}

.pagination ul li .page-link.active {
  background-color: #ce7ffd;
  /* border: unset; */
  color: #fff;
}

.pagination ul li .page-link {
  margin-right: 5px;
  border-radius: 5px;
}

.page-link.active {
  background-color: #ce7ffd;
  border-color: #ce7ffd;
  color: #fff;
}

/* a:hover .page-link{
    background-color: #ce7ffd;
    color: #fff;
} */

/* .retailer_pagination .page-item .page-link{
    margin-right: 8px;
    color: #ce7ffd;
    /* border-radius: 5px; */
/* } */
/* .retailer_pagination{
    display: flex;
    align-items: center;
    justify-content: center;
}  */

/* navbar */
.navbar-brand-wrapper .nav_logo_bar {
  height: 55px;
  max-width: 200px;
  width: 100%;
  margin-bottom: 0;
  margin-left: 10px;
}

.nav_logo_bar img {
  width: 100% !important;
  object-fit: cover;
  height: 100% !important;
}

/* Login Css */

.content-wrapper .LoginArea {
  align-items: start;
  align-items: center;
  border: 1px solid #aaaaaa2e;
  border-radius: 12px;
  box-shadow: 0 8px 24px #6dafd477;
  display: flex;
  margin: 0 auto;
  max-width: 500px;
  min-height: calc(100vh - 135px);
  padding: 10px 30px;
  width: 100%;
}

.content-wrapper .LoginArea .image_wrap {
  margin: 0 auto 0px;
  max-width: 140px;
  width: 100%;
}

.content-wrapper .LoginArea .image_wrap img {
  width: 100%;
}

.content-wrapper .LoginArea .form_title {
  padding: 10px 0 25px;
  text-align: center;
}

.content-wrapper .LoginArea .form_title h2 {
  display: none;
  font-weight: 700;
  margin-bottom: 10px;
}

.content-wrapper .LoginArea .form_title p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.login_input .form_field {
  position: relative;
  margin-bottom: 20px;
  text-align: start;
}

.content-wrapper .LoginArea label {
  padding: 0 0 5px 7px;
  font-size: 14px;
}

.content-wrapper .LoginArea form {
  width: 100%;
}

.login_input .form_field .input {
  border-radius: 20px;
  padding-left: 40px;
}

.login_input .form_field.isError .Icon {
  bottom: 27px;
}

.login_input .form_field.isError .toggle {
  bottom: 32px;
  right: 27px;
}

.login_input .form_field .Icon {
  bottom: 7px;
  color: blueviolet;
  font-size: 25px;
  left: 8px;
  position: absolute;
}

.content-wrapper .LoginArea .invalid-feedback {
  padding-left: 5px;
}

.login_btn_group {
  padding-top: 30px;
}

.login_input .form_field .toggle {
  bottom: 11px;
  color: blueviolet;
  font-size: 18px;
  right: 8px;
  position: absolute;
  cursor: pointer;
}

.cstm_padding_input {
  padding-right: 40px;
}

.nav-item .active {
  color: #ce7ffd !important;
}

.sidebar .nav .nav-item .active i.menu-icon {
  color: #ce7ffd;
}

.page-body-wrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: calc(100vh - 70px);
  padding-left: 0;
  padding-right: 0;
}

.sidebar {
  background: #fff;
  left: 0;
  min-height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 0;
  position: fixed;
  top: 70px;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  width: 260px;
  z-index: 11;
}

/* / Upload Image Close Button  / */
.cstm_close_icon {
  position: absolute;
  top: 2px;
  font-size: 24px;
  color: blueviolet;
  cursor: pointer;
  right: 25px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

/* cancel button div   */
.cstm_upload_image {
  position: relative;
}

/* Select box  */
.select.form-control {
  padding: 0 !important;
  color: unset !important;
}

.custm_width {
  width: 385px;
}


.react-datepicker-wrapper_retailer {
  width: 100%;
}

.react-datepicker-wrapper_retailer .react-datepicker-wrapper {
  width: 100%;

}


/*Notification Button  */
.notification_send_btn {
  position: absolute;
  top: 120px;
  right: 40px;
}

.notification_btn {
  position: relative;
}

.cstm_wrap_wallet {
  align-items: baseline;
}

.cstm_wrap_wallet .text_end {
  justify-content: end;

}



/* Image Input Style */

.file-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  cursor: pointer;
}

.file-input.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.file-input.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://https://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.file-wrap .invalid-feed {
  color: #dc3545 !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

/* .file-input:hover ~ .file-input .choose {
  background: #848485;
} */

.file-input .choose {
  background: #f8f9fa;
  padding: 7px 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.file-input .file-name {
  padding: 7px 10px;
}

.cstm_nav .nav-item {
  padding: 0px 10px 0px 20px !important;
}

.cstm_wrap_navbar_wrapper {
  display: flex;
  align-items: center;
}

.notification_btn .row .MuiFormControl-root .MuiInputBase-root fieldset {
  border-color: #ebedf2 !important;
}

.margin {
  margin-left: 150px;
}